import React, {Component} from "react";
import CONSTANT from "../../../utils/constant";
import Logger from "../../../utils/logger";
import {DropDownElement, FreeTextElement} from "../../../component/dataEntry";
import {
    ColumnLayout,
    Button,
    ProgressBar,
    DatePicker,
    FormField,
    Modal,
    SpaceBetween,
    Box
} from "@amzn/awsui-components-react";
import '@amzn/awsui-global-styles/polaris.css';
import "../../../style/batchUpdatePaymentModal.css"
import FormatData from "../../../utils/formatData";
import {PolarisV3FlashBar} from "../../../component/dataDisplay";


// UI Modal for the UpdatePayment Window.
class BatchUpdatePaymentModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            inputValueDate: '',
            inputPaymentType: '',
            inputCancellationReason: '',
            additionalUpdateDetails:  '',
            cancellationReasonDropdownDisabled: true,
            additionalUpdateReasonDisabled: true,
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: '',
            rejectionWindowVisible: false,
            validationFailureMessage: '',
            reviewWindowVisible : false
        }


        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleValueDateChange = this.handleValueDateChange.bind(this);
        Logger.logInfo("Intialized BatchUpdatePaymentModal");
    }

    // Handle Value Change
    handleValueChange = (name, value) => {
        Logger.logInfo("UpdatePaymentModal handleValueChange: " + name + " " + value);
        this.setState({[name]: value});
    };

    // Handle ValueDate Change
    handleValueDateChange = (detail) => {
        Logger.logInfo("UpdatePaymentModal handleValueDateChange: "  + detail.value);
        this.setState({inputValueDate: detail.value});
    };

    // Handle Payment Type Value Change
    handlePaymentTypeValueChange = (name, value) => {
        Logger.logInfo("handlePaymentTypeValueChange: " + name + " " + value);
        let cancellationReasonDropdownDisabled = value === 'Cancelled' ? false: true;
        Logger.logInfo("cancellationReasonDropdownDisabled:" + cancellationReasonDropdownDisabled);

        this.setState({
            [name]: value,
            cancellationReasonDropdownDisabled: cancellationReasonDropdownDisabled
        });

        if(cancellationReasonDropdownDisabled) {
            this.setState({
                inputCancellationReason : '',
                additionalUpdateReasonDisabled: false
            });
        } else {
            this.setState({
                additionalUpdateReasonDisabled: true,
            });
        }
    };

    // Handle Payment Type Value Change
    handleCancellationReasonValueChange = (name, value) => {
        Logger.logInfo("handleCancellationReasonValueChange: " + name + " " + value);
        let additionalUpdateReasonDisabled = value === 'Other' ? false: true
        this.setState({[name]: value});
        this.setState({additionalUpdateReasonDisabled: additionalUpdateReasonDisabled});

        if (additionalUpdateReasonDisabled) {
            this.setState({
                additionalUpdateDetails : ''
            });
        }
    };

    resetAdditionalUpdateReason = () => {
        if (this.state.additionalUpdateReasonDisabled) {
            this.setState({additionalUpdateDetails: ''});
        }
    }

    // Get the list of selection options for Payment Type Dropdown element
    getPaymentTypeSelections = () => {
        return  CONSTANT.PAYMENT_TYPE_LIST_BATCH_UPDATE.map(paymentType => (({
            label: paymentType,
            id: paymentType,
            value: paymentType
        })));
    }

    // Get the list of cancellation reasons for the dropdown element
    getCancellationReasons = () => {
        return  CONSTANT.CANCELLATION_REASON_LIST.map(cancellationReason => (({
            label: cancellationReason,
            id: cancellationReason,
            value: cancellationReason
        })));
    }

    // Render the Modal message and progress bar based on the modal state
    getFeedbackMessage = () => {
        switch (this.state.status) {
            case CONSTANT.MODAL_QUERY_SUCCESS:
                return (
                    <ProgressBar
                        status="success"
                        resultText= {this.state.statusMsg}
                    />);
            case CONSTANT.MODAL_QUERY_ERROR:
                return (
                    <ProgressBar
                        status="error"
                        resultText= {this.state.statusMsg}
                    />);
            case CONSTANT.MODAL_UPDATE_VALIDATION_FAIL:
                /* Fully uncontrolled component with a key approach per react documentation
                 https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-uncontrolled-component-with-a-key
                to force creating new instance of Flashbar on props changes. */
                return (<PolarisV3FlashBar
                    dismissible={true}
                    content={this.state.validationFailureMessage}
                    type="error"
                    key={this.state.validationFailureMessage}/>);
            default:
                return;
        }
    }

    /**
     * Validate whether rejection reason & additional rejection reason are null if paymentType is Cancelled.
     */
    validateUpdateFields = () => {
        if (this.state.inputPaymentType === 'Cancelled') {
            //Cancellation Reason cannot be empty if the paymentType is chosen as Cancelled.
            if (!CONSTANT.CANCELLATION_REASON_LIST.includes(this.state.inputCancellationReason)) {
                // this.setState({status: CONSTANT.MODAL_UPDATE_EMPTY_REJECTION_REASON});
                const ruleName = 'EMPTY_REJECTION_REASON';
                this.setState({
                    status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                    validationFailureMessage: CONSTANT.UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP[ruleName]
                });
                return false;
            } else if (this.state.inputCancellationReason === 'Other') {
                //Addtional cancellation Reason cannot be empty if the Cancellation Reason is chosen as Other.
                if (this.state.additionalUpdateDetails === '') {
                    const ruleName = 'EMPTY_ADDITIONAL_REJECTION_REASON';


                    this.setState({
                        status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                        validationFailureMessage: CONSTANT.UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP[ruleName]
                    });
                    return false;

                }
            }
        }
        // Settled Early cannot have empty Update Reason
        if (this.state.inputPaymentType === 'Settled Early') {
            if (this.state.additionalUpdateDetails === '') {
                const ruleName = 'EMPTY_ADDITIONAL_REJECTION_REASON';
                this.setState({
                    status: CONSTANT.MODAL_UPDATE_VALIDATION_FAIL,
                    validationFailureMessage: CONSTANT.UPDATE_VALIDATION_RULE_TO_MESSAGES_MAP[ruleName]
                });
                return false;
            }
        }

        return true;
    }

    /**
     * Handler function for reviewing the batch change before submitting the update.
     */
    handleReviewClick = () => {
        if (!this.validateUpdateFields()) {
            return;
        }
        this.setState({reviewWindowVisible : true});
    }

    /**
     * Handler function for dismissing the review window
     */
    handleDismissReviewWindowClick = () => {
        Logger.logInfo("handleDismissReviewWindowClick Start: ");
        this.setState({reviewWindowVisible: false})
    }

    generateReviewMessage = () => {
        let numItemSelected = this.props.elementSelected.length;

        let newValueDate = this.state.inputValueDate === '' ? 'unchanged' : this.state.inputValueDate;
        let newPaymentType = this.state.inputPaymentType === '' ? 'unchanged' : this.state.inputPaymentType;
        let newCancellationReason = this.state.inputCancellationReason === '' ?
            'unchanged' : this.state.inputCancellationReason;
        let newAdditionalUpdateReason = this.state.additionalUpdateDetails === '' ?
            'unchanged' : this.state.additionalUpdateDetails;

        const numItemMessage = "You're about to batch update " +  numItemSelected + " payments to: " ;
        const valueDateMessage = "ValueDate: " + newValueDate + '\n';
        const paymentTypeMessage = "PaymentType: " + newPaymentType + '\n';
        const cancellationReasonMessage = "CancellationReason: " + newCancellationReason + '\n';
        const additionalUpdateReasonMessage = "AdditionalUpdateReason: " + newAdditionalUpdateReason + '\n';

        return <div>
            <p>{numItemMessage}</p>
            {<ul>
                <li>{valueDateMessage} </li>
                <li>{newPaymentType === 'unchanged' ? '' : paymentTypeMessage} </li>
                <li> {newCancellationReason === 'unchanged' ? '' : cancellationReasonMessage} </li>
                <li> {newAdditionalUpdateReason === 'unchanged' ? '' : additionalUpdateReasonMessage} </li>
            </ul>}
        </div>
    }


    /*  Handler function for confirming updates on a payment.
    This function simply updates fields in a payment record based on front-end UI selections and delegate the call to
    parent update handler for invoking PUT method on relative API endpoints. */
    handleUpdateClick = () => {

        this.props.elementSelected.map((entry, index) => {

            // Update the Payment Type if user selected a new one.
            let paymentTypeChanged = false;
            if (this.state.inputPaymentType !== '') {
                paymentTypeChanged = this.props.elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] !== this.state.inputPaymentType;
                this.props.elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] = this.state.inputPaymentType;
            }

            // Update the Value Date if user selected a new one.
            if (this.state.inputValueDate !== '') {
                this.props.elementSelected[index][CONSTANT.PR_COL_VALUE_DATE] = this.state.inputValueDate;
            }

            // For Updating Payment Type to Settled Early or Cancelled.
            // Cancelled payments should update status to Rejected, Settled Early should update status to Closed
            if (paymentTypeChanged) {
                let currentUTCTimeString = FormatData.formateNewUTCCurrentTime();
                let commentData = this.props.elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS] ?
                    JSON.parse(this.props.elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS]) : [];

                let updateComment = this.state.additionalUpdateDetails

                if (this.props.elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Settled Early') {
                    this.props.elementSelected[index][CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_CLOSED;
                    this.props.elementSelected[index][CONSTANT.PR_COL_CLOSE_DATE] = currentUTCTimeString;
                    this.props.elementSelected[index][CONSTANT.PR_COL_CLOSED_BY] = this.props.user;

                } else if (this.props.elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] === 'Cancelled') {
                    this.props.elementSelected[index][CONSTANT.PR_COL_PAYMENT_TYPE] = 'Cancelled';
                    this.props.elementSelected[index][CONSTANT.PR_COL_STATUS] = CONSTANT.PAYMENT_STATUS_REJECTED;
                    this.props.elementSelected[index][CONSTANT.PR_COL_REJECTION_DATE] = currentUTCTimeString;
                    this.props.elementSelected[index][CONSTANT.PR_COL_REJECTED_BY] = this.props.user;
                    this.props.elementSelected[index][CONSTANT.PR_COL_ADDITIONAL_CANCELLATION_REASON] =
                        this.state.additionalUpdateDetails;
                    this.props.elementSelected[index][CONSTANT.PR_COL_CANCELLATION_REASON] = this.state.inputCancellationReason;

                    updateComment = (this.state.inputCancellationReason === 'Other') ?
                        this.state.additionalUpdateDetails : this.state.inputCancellationReason;
                    updateComment = CONSTANT.PAYMENT_STATUS_REJECTED + ' - ' + updateComment;
                }
                let updateMessageCommentObject = {};
                updateMessageCommentObject["Comment"] = updateComment;
                updateMessageCommentObject["User"] = this.props.user;
                updateMessageCommentObject["CommentDate"] = currentUTCTimeString;
                commentData.push(updateMessageCommentObject);
                Logger.logInfo("commentData: " + JSON.stringify(commentData));
                this.props.elementSelected[index][CONSTANT.PR_COL_USER_COMMENTS] = JSON.stringify(commentData);
            }
        });

        this.setState({reviewWindowVisible : false});

        this.props.handleConfirmBatchUpdateClick(this.props.elementSelected);
    }

    resetStateDataAndDismiss = () => {

        this.setState({
            inputValueDate: '',
            inputPaymentType: '',
            inputCancellationReason: '',
            additionalUpdateDetails:  '',
            cancellationReasonDropdownDisabled: true,
            additionalUpdateReasonDisabled: true,
            status: CONSTANT.MODAL_EMPTY,
            statusMsg: '',
            rejectionWindowVisible: false,
            validationFailureMessage: '',
            reviewWindowVisible : false
        });

        this.props.handleDismissClick();
    }

    /**
     * Determine whether a value date is a valid/enabled based on these rules:
     *  1. The value date has to be in the future compared to the current date.
     */
    isValueDateValid = (valueDate) => {

        return this.isValueDateNotBeforeCurrentDate(valueDate);
    }

    /**
     * Determine whether a valueDate is NOT before the Current Date, current date being in browser's locale time.
     * @param valueDate
     */
    isValueDateNotBeforeCurrentDate = (valueDate) => {
        const currentDate = new Date().setHours(0,0,0,0);
        return valueDate >= currentDate;
    }

    /**
     * Determine whether user have inputted any information through UI in this modal.
     */
    isUserInputProvided = () => {
        return this.state.inputValueDate !== '' || this.state.inputPaymentType !== '';
    }

    // Render UI elements and route handler methods together.
    render() {
        return  <Modal
            visible={this.props.batchUpdateWindowVisible}
            header="Batch Update Settlement"
            expandToFit={true}
            onDismiss={this.resetStateDataAndDismiss}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                                onClick={() => this.resetStateDataAndDismiss()}>Cancel</Button>
                        <Button variant="primary"
                                disabled={!this.isUserInputProvided()}
                                onClick={() => this.handleReviewClick()}
                        >Review</Button>
                    </SpaceBetween>
                </Box>}>

                           <ColumnLayout>
                                <div data-awsui-column-layout-root={true}>
                                    <PolarisV3FlashBar
                                        dismissible={true}
                                        content={"Leaving a field empty will leave the field of selected settlements untouched,\n" +
                                            " you will get to review the change you're about to make to the selected settlements after you click review button."}
                                        type="info"
                                        key={this.state.status}/>
                                    <FormField label="Value Date">
                                        <DatePicker
                                            onChange={event => {
                                                this.setState({ inputValueDate: event.detail.value })
                                                Logger.logInfo("valueDate afeterchange: " + this.state.inputValueDate)
                                            }}
                                            isDateEnabled={date =>
                                                this.isValueDateValid(date)
                                            }
                                            value={this.state.inputValueDate}
                                            nextMonthAriaLabel="Next month"
                                            placeholder="YYYY/MM/DD"
                                            previousMonthAriaLabel="Previous month"
                                            todayAriaLabel="Today"/>
                                    </FormField>
                                    <ColumnLayout columns={1}>
                                        <div>
                                            {this.getFeedbackMessage()}
                                        </div>
                                    </ColumnLayout>
                                    <div>
                                        <Modal
                                            visible={this.state.reviewWindowVisible}
                                            header="Review"
                                            onDismiss={this.handleDismissReviewWindowClick}

                                            footer={<Box float="right">
                                                <SpaceBetween direction="horizontal" size="xs">
                                                    <Button variant="link"
                                                            onClick={() => this.handleDismissReviewWindowClick()}>Cancel</Button>
                                                    <Button variant="primary"
                                                            disabled={false}
                                                            onClick={() => this.handleUpdateClick()}>Confirm Update</Button>
                                                </SpaceBetween>
                                            </Box>}>
                                            {this.generateReviewMessage()}
                                        </Modal>
                                     </div>
                                    <ColumnLayout columns={3}>
                                        <div>
                                            <DropDownElement title={'Payment Type'}
                                                             name={'inputPaymentType'}
                                                             value={this.state.inputPaymentType}
                                                             options={this.getPaymentTypeSelections()}
                                                             disabled={this.props.rejectionDisabled}
                                                             required={false}
                                                             handleChange={this.handlePaymentTypeValueChange}
                                            />
                                        </div>
                                        <div>
                                        <DropDownElement title={'Cancellation Reason'}
                                                         name={'inputCancellationReason'}
                                                         value={this.state.inputCancellationReason}
                                                         options={this.getCancellationReasons()}
                                                         disabled={this.state.cancellationReasonDropdownDisabled}
                                                         handleChange={this.handleCancellationReasonValueChange}
                                        />

                                        </div>
                                    </ColumnLayout>
                                    <ColumnLayout columns={1}>
                                        <FreeTextElement title={"Additional Update Details"}
                                                         name={"additionalUpdateDetails"}
                                                         value={this.state.additionalUpdateDetails}
                                                         errorText={"ErrorText"}
                                                         disabled={this.state.additionalUpdateReasonDisabled}
                                                         required={true}
                                                         handleChange={this.handleValueChange}
                                        />
                                    </ColumnLayout>
                                </div>
                           </ColumnLayout>

        </Modal>;
    }
}

export default BatchUpdatePaymentModal;